import * as React from "react";
import styeld from "styled-components";
import { useEffect, useState } from "react";
import noImage from "../../images/noneImg.svg";
const Containar = styeld.div`
margin: 8vw 0 19vw;
.newsroom-text{
  width: 62%;
  margin: 0 auto;
  border-bottom: 3px solid #222222;
 
  .newsroom-title{
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.6vw;
    margin: 2vw 0;
  }
}
.newsroom-list{
  width: 62%;
  display: flex;
  margin: 0 auto;
  padding: 1vw 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  cursor:pointer;
  .newsroom-text-part {
    width: 43vw;
    .newsroom-text-write{
      font-size: 0.8vw;
      margin-bottom: 0.8vw;
    }
    .newsroom-text-title{
      font-size: 1.3vw;
      line-height:1.3;
    }
  }
  
  .newsroom-image{
    width: 20vw;
    height: 13vw;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.viewMorebtn {
  font-size: 1.1vw;
  text-align: center;
  margin: 2vw;
  cursor:pointer;
}
@media screen and (max-width: 1020px) {

  .newsroom-text{
   
    margin: 0 auto;
    width: 80%;
  .newsroom-title {
    font-size: 5vw;
    }
  }
  .newsroom-list{
    width: 80%;
    .newsroom-text-part {
      width: 50vw;
      .newsroom-text-write{
        font-size: 1.2vw;
      }
      .newsroom-text-title{
        font-size: 2vw;
      }
    }
  }
 
}
@media screen and (max-width: 460px) {
  .newsroom-text {
    width:90%;
   
    .newsroom-title {
      font-size: 6vw;
      margin: 2vw 0 8vw;
      
    }
  }
  .newsroom-list{
    width:90%;
    border-bottom: 1px solid rgba(241, 241, 241, 1);
    .newsroom-text-part {
      width: 100%;
      margin: 4vw 2vw;
      .newsroom-text-title {
        width: 85vw;
        font-size: 4vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .newsroom-text-write {
        font-size: 3vw;
        margin-bottom: 1.5vw;
      }
    }
    .newsroom-image {
      display:none;
    }
  }

  .viewMorebtn {
    font-size: 3.3vw;
    margin: 7vw;
  }
}
`;
let view;
if (typeof window !== "undefined") {
  view = document.getElementsByClassName("viewMorebtn");
}

// markup
let count = 5;
const NewsRoom2 = ({ lists }) => {
  const [showData, setShowData] = useState([]);

  useEffect(() => {
    let listData = [...lists];
    listData.length = count;
    enterData(listData);
  }, []);

  const enterData = (listData) => {
    setShowData(listData);
  };
  const clickViewMore = () => {
    count = count + 5;
    let listData = [...lists];
    listData.length = count;
    enterData(listData);
    if (count > lists.length) {
      view[0].style.display = "none";
    }
  };

  const handleImgError = (e) => {
    e.target.src = noImage;
  };

  return (
    <Containar>
      <div>
        <div className="newsroom-text">
          <p className="newsroom-title">Press Release</p>
        </div>
        {showData.map((newslist, index) => (
          <div>
            <div
              className="newsroom-list"
              key={index}
              onClick={() => window.open(`${newslist.url}`, "_blank")}
            >
              <div className="newsroom-text-part">
                <p className="newsroom-text-write">{newslist.writedate}</p>
                <p className="newsroom-text-title">{newslist.title}</p>
              </div>
              <div className="newsroom-image">
                <img alt="img97" src={newslist.image} onError={handleImgError} />
              </div>
            </div>
          </div>
        ))}
        <div className="viewMorebtn" onClick={clickViewMore}>
          View More
        </div>
      </div>
    </Containar>
  );
};

export default NewsRoom2;
