import * as React from "react";
import Layout from "../components/layout/layout";
import styeld from "styled-components";
import NewsRoom1 from "../components/newsRoom/newsRoom1";
import NewsRoom2 from "../components/newsRoom/newsRoom2";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const Containar = styeld.div``;
const pageName = "뉴스룸";
const tag_title = `${pageName} - 샘랩`;
const tag_description = `샘랩의 ${pageName} 페이지 입니다.`;

// markup
const NewsRoom = ({ data }) => {
  const {
    allStrapiPaperlists: { nodes: lists },
  } = data;
  return (
    <Layout>
      <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/newsroom" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr/newsroom" />
      </Helmet>
      <Containar>
        <NewsRoom1 />
        <NewsRoom2 lists={lists} />
      </Containar>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiPaperlists(sort: { fields: writedate, order: DESC }) {
      nodes {
        title
        contents
        image
        writedate
        url
      }
    }
  }
`;

export default NewsRoom;
