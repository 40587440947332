import * as React from "react";
import styeld from "styled-components";
import newsRoom from "../../images/newsroom/newsroom.png";
import newsRoom2 from "../../images/newsroom/newsroom-480.png";

const Containar = styeld.div`
.newsroom-text{
  position: absolute;
  margin: 8vw 0 0 24.8vw;
.newsroom-title {
  margin-top: 90px;
  font-family: 'Pretendard-ExtraBold';
  font-size: 4vw;
  color:#ffffff;
  }
}

.newsroom-title_img{
  img{
    margin-top: 4vw;
    width: 100%;
  }
}

.show-460 {
  display: none;
}
@media screen and (max-width: 1020px) {
  .newsroom-text{
   
    margin: 8vw 0 0 10%;
  .newsroom-title {
   
 
    font-size: 5vw;
    }
  }
  .newsroom-title_img{
    img{
      margin-top: 6vw;
     
    }
  }
}
@media screen and (max-width:460px) {
  .newsroom-text{
    margin: 15vw 0 0 11.5vw;
  .newsroom-title {
    font-size: 7vw;
    }
  }

  .newsroom-title_img{
    width: 100%;
    height: 30vh;
    padding-top: 15vw;
  .hide-460 {
      display: none;
    }
  .show-460{
      display: block;
      height: 100%;
      margin-top: 0;
    }
  }
}
`;

// markup
const NewsRoom1 = () => {
  return (
    <Containar>
      <div>
        <div className="newsroom-text">
          <p className="newsroom-title">NEWSROOM</p>
        </div>
        <div className="newsroom-title_img">
          <img alt="img95" className="hide-460" src={newsRoom} />
          <img alt="img96" className="show-460" src={newsRoom2} />
        </div>
      </div>
    </Containar>
  );
};

export default NewsRoom1;
